import {
    memo,
    useState,
    type ReactNode
} from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LoadingButton, { type LoadingButtonProps } from "@mui/lab/LoadingButton";
import { TriggerableDialog } from "ui/molecules/Dialog";

type Props = {
    readonly isDeletable: boolean;
    readonly renderDialogTitle: () => ReactNode;
    readonly onDelete: () => Promise<void>;
    readonly renderHint?: () => ReactNode;
    readonly DialogActionProps?: LoadingButtonProps;
};

const DeleteBusinessEntity = ({
    isDeletable,
    renderDialogTitle,
    onDelete,
    renderHint,
    DialogActionProps
}: Props) => {
    const [isDeleting, setDeleting] = useState(false);

    const isTriggerDisabled = (
        !isDeletable ||
        isDeleting
    );

    return (
        <TriggerableDialog
            TriggerComponent={({ onClick }) => (
                <Tooltip
                    arrow
                    placement='top'
                    title={isTriggerDisabled && renderHint?.()}
                >
                    <Box
                        sx={{
                            width: 'min-content'
                        }}
                    >
                        <IconButton
                            color='error'
                            onClick={onClick}
                            disabled={isTriggerDisabled}
                        >
                            <DeleteForeverIcon />
                        </IconButton>
                    </Box>
                </Tooltip>
            )}
            DialogActionsComponent={({ onClose }) => (
                <>
                    <LoadingButton
                        variant='contained'
                        color='error'
                        onClick={() => {
                            setDeleting(true);

                            onDelete()
                                .then(() => onClose())
                                .finally(() => setDeleting(false));
                        }}
                        loading={isDeleting}
                        {...DialogActionProps}
                    >
                        Delete
                    </LoadingButton>
                    <Button
                        onClick={onClose}
                    >
                        Cancel
                    </Button>

                </>
            )}
            ModalProps={{
                DialogProps: {
                    sx: {
                        '& .MuiDialog-container .MuiPaper-root': {
                            width: 'auto',
                            '& .MuiDialogContent-root': {
                                p: 0,
                                border: 'none'
                            }
                        }
                    }
                },
                titleSlot: renderDialogTitle()
            }}
        />
    );
};

export default memo(DeleteBusinessEntity);
