import { useState } from "react";
import { useFileUpload } from "hooks";
import { ResponseCode } from "consts/api";
import { useError, useUpload, type CustomFile } from "lib/upload";
import BusinessLogicException from "exceptions/BusinessLogicException";
import { ManagementApiResponseFactory } from "util/api";
import { getPromiseSettledResourceResult } from "util/resource";
import type { UseFileUploaderArg } from "../types";

export default function useFileUploader({
    url,
    body,
    getBody
}: UseFileUploaderArg) {
    const [isProcessing, setProcessing] = useState(false);

    const {
        files,
        setFiles,
        reset,
        ...restUpload
    } = useUpload();

    const {
        record,
        clear
    } = useError({ setFiles });

    const uploadFiles = useFileUpload();

    const getUploadFilesBody = (file: CustomFile) => ({
        url,
        files: [
            {
                ...(body || {}),
                ...getBody?.(),
                file
            }
        ]
    });

    const upload = async () => {
        setProcessing(true);

        const responses = await Promise.allSettled(
            files.map(file => uploadFiles(
                getUploadFilesBody(file)
            ))
        );

        let hasError = false;

        responses.forEach((response, index) => {
            const result = getPromiseSettledResourceResult(response);

            if (result && !result.success) {
                record(index, result);

                hasError = true;
            }
        });

        if (hasError) {
            setProcessing(false);

            throw new BusinessLogicException('Failed to upload files', {});
        }

        setProcessing(false);

        reset();

        return ManagementApiResponseFactory.make({
            code: ResponseCode.Ok,
            success: true,
            message: 'Files uploaded successfully'
        });
    };

    const retry = (file: CustomFile | string) => {
        if (typeof file === 'string') {
            return;
        }

        clear(file);

        return uploadFiles({
            ...getUploadFilesBody(file),
            requestSettings: {
                notifyOnSuccess: true,
                onError: response => record(file, response)
            }
        });
    };

    return {
        ...restUpload,
        isProcessing,
        files,
        reset,
        upload,
        retry
    };
};
