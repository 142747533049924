import type { BoxProps } from "@mui/material";
import type { useFormik } from "formik";
import type { BooleanSwitch } from "types";

export const enum FormField {
    Reason = 'reason',
    AsPerson = 'asPerson'
};

export type ImportConsumerBlacklist = {
    readonly [FormField.Reason]: string;
    readonly [FormField.AsPerson]: BooleanSwitch;
};

export type ImportConsumerBlacklistProps =
    & BoxProps
    & ReturnType<typeof useFormik<ImportConsumerBlacklist>>;
