import type { Scalar } from "types";
import { FORM_DATA_FILE_KEY } from "consts/forms";
import { HttpVerb } from "consts/api";
import {
    useSimpleRequest,
    type UseSimpleRequestReturnCallbackArg,
    type UseSimpleRequestArg
} from "./useRequest";
import { fetchApi, FormSerializers } from "infrastructure/api";

export type FileUploadRequestBody<TExtra extends object> = {
    readonly file: File;
} & TExtra;

export default function useFileUpload({
    requestSettings
}: {
    readonly requestSettings?: UseSimpleRequestArg;
} = {}) {
    const request = useSimpleRequest(requestSettings);

    return <
        TExtra extends object,
        TResponse extends unknown
    >({
        url,
        files,
        requestSettings,
        paramName = FORM_DATA_FILE_KEY,
        ...restOptions
    }: {
        readonly url: string;
        readonly files: Array<FileUploadRequestBody<TExtra>>;
        readonly paramName?: string;
        readonly requestSettings?: UseSimpleRequestReturnCallbackArg<TResponse>;
    } & Parameters<typeof fetchApi>[1]) => {
        const body = new FormData();

        for (const { file, ...restBody } of files) {
            body.append(paramName, file);

            Object.entries(restBody)
                .forEach(([key, value]: [string, Scalar<unknown>]) => {
                    body.append(key, value as string);
                });
        }

        return request(() => fetchApi(url, {
            method: HttpVerb.Post,
            ...restOptions,
            serializer: FormSerializers.FormData,
            body
        }), requestSettings);
    };
};
