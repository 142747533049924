import { memo, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { ProcessResponseStrategy, useSimpleRequest } from "hooks/useRequest";
import { downloadImportConsumerBlacklistTemplate } from "features/consumers/api";
import { NotificationSeverity } from "features/general/types";
import useActions from "features/general/useActions";

const ImportBlacklistConsumersTemplate = () => {
    const [isLoading, setLoading] = useState(false);

    const { showNotication } = useActions();

    const request = useSimpleRequest({
        setLoading,
        processResponseStrategy: ProcessResponseStrategy.Text
    });

    const downloadTemplate = () => (
        request(downloadImportConsumerBlacklistTemplate)
            .catch(() => {})
            .finally(() => showNotication({
                message: 'Template downloaded successfully',
                severity: NotificationSeverity.Success
            }))
    );

    return (
        <LoadingButton
            variant='outlined'
            color='info'
            loading={isLoading}
            onClick={downloadTemplate}
        >
            Download Template
        </LoadingButton>
    );
};

export default memo(ImportBlacklistConsumersTemplate);
