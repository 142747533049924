import type { Dispatch, SetStateAction } from "react";
import type { CustomFile } from "../types";

type UseErrorArg = {
    readonly setFiles: Dispatch<SetStateAction<CustomFile[]>>;
};

export default function useError({ setFiles }: UseErrorArg) {
    const record = (fileOrIndex: CustomFile | number, { message }: {
        readonly message: string;
    }) => (
        setFiles(files => files.map((file, index) => {
            if (Object.is(file, fileOrIndex) || Object.is(index, fileOrIndex)) {
                file.error = {
                    toString: () => message
                };
            }

            return file;
        }))
    );

    const clear = (file: CustomFile) => (
        setFiles(files => files.map(f => {
            if (Object.is(f.name, file.name)) {
                delete f.error;
            }

            return f;
        }))
    );

    return {
        record,
        clear
    };
};
