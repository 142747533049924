import { useFormik, type FormikConfig } from "formik";
import { BooleanSwitchValue } from "consts/general";
import { FormField, type ImportConsumerBlacklist } from "./types";
import useValidationSchema from "./useValidationSchema";

export const getInitialValues = (initialValues: Partial<ImportConsumerBlacklist> = {}) => ({
    [FormField.Reason]: '',
    [FormField.AsPerson]: BooleanSwitchValue.Off,
    ...initialValues
});

export const useForm = (importConsumerBlacklistForm?: FormikConfig<ImportConsumerBlacklist>) => {
    const {
        initialValues,
        ...restFormArgs
    } = importConsumerBlacklistForm ?? {};

    return useFormik({
        enableReinitialize: true,
        validateOnMount: true,
        ...restFormArgs,
        onSubmit: () => {},
        validationSchema: useValidationSchema(),
        initialValues: getInitialValues(initialValues)
    });
};
