import { memo } from "react";
import Resource from 'ui/layouts/Resource';
import BlacklistedConsumersTable from "./BlacklistedConsumersTable";
import { ImportBlacklistConsumers } from "./BlacklistedConsumersActions";

const BlacklistedConsumers = () => (
    <Resource
        breadcrumbsProps={{
            heading: 'Blacklisted Consumers',
            links: [{ name: '' }],
            action: (
                <ImportBlacklistConsumers />
            )
        }}
    >
        <BlacklistedConsumersTable />
    </Resource>
);

export default memo(BlacklistedConsumers);
