import { MenuItem } from "consts/consumers";
import { ApiState } from "infrastructure/api";
import type { RootState } from "infrastructure/store";

export const selectAssertConsumersTableLoadingState = (apiState: ApiState) =>
    (consumersTab: MenuItem) =>
        (state: RootState) =>
            Object.is(state.consumers[consumersTab].tableLoadingState, apiState);

export const selectIsConsumersUninitialized = (consumersTab: MenuItem) => (state: RootState) =>
    !selectAssertConsumersTableLoadingState(ApiState.Idle)(consumersTab)(state);

export const selectIsConsumersLoading = selectAssertConsumersTableLoadingState(ApiState.Pending);

export const selectConsumersSlice = (consumersTab: MenuItem) => (state: RootState) =>
    state.consumers[consumersTab].tableSlice;

export const selectIsExportConsumersLoading = (consumersTab: MenuItem) => (state: RootState) =>
    state.consumers[consumersTab].exportLoadingState === ApiState.Pending;
