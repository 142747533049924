import { memo } from "react";
import { TableGridMode } from "consts/table";
import useResetState from "hooks/useResetState";
import { resetBlacklistedConsumersState as resetState } from "features/consumers/slice";
import {
    selectConsumersSlice,
    selectIsConsumersLoading,
    selectIsConsumersUninitialized
} from "features/consumers/selectors";
import Table from "ui/widgets/Table";
import { MenuItem } from "consts/consumers";
import useBlacklistedConsumersTable from "./useBlacklistedConsumersTable";
import * as tableComponents from "../../ConsumerTableComponents";

const BlacklistedConsumersTable = () => {
    const {
        isRowSelectable,
        filterTypes,
        getConsumers,
        downloadCsv
    } = useBlacklistedConsumersTable();

    return (
        <Table
            disableSelectionOnClick
            checkboxSelection
            mode={TableGridMode.BlacklistedConsumers}
            resetState={useResetState(resetState)}
            isRowSelectable={isRowSelectable}
            fetchTableData={getConsumers}
            filterTypes={filterTypes}
            selectIsTableUninitialized={selectIsConsumersUninitialized(MenuItem.Blacklisted)}
            selectIsTableLoading={selectIsConsumersLoading(MenuItem.Blacklisted)}
            selectTableDataSlice={selectConsumersSlice(MenuItem.Blacklisted)}
            downloadCsv={downloadCsv}
            components={tableComponents}
            LayoutProps={{
                sx: {
                    mx: 0
                }
            }}
            FiltersProps={{
                sx: {
                    mx: 0
                },
                isDefaultFiltersOpen: true
            }}
        />
    );
};

export default memo(BlacklistedConsumersTable);
