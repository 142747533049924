import { useCallback, useState } from 'react';
import { blobDownload } from 'util/download';

export type UseDownloadable = {
    readonly downloadFile: (fileKey: string) => Promise<Response>;
};

export default function useDownloadable({ downloadFile }: UseDownloadable) {
    const [isDownloading, setDownloading] = useState(false);

    const onDownload = useCallback((file: string | File) => {
        setDownloading(true);

        const fileDownloadPromise = (file instanceof File)
            // TODO: uncomment when update to latest TS version
            ? (Promise as any).try(blobDownload, {
                fileName: file.name,
                obj: file
            })
            : downloadFile(file);

        return fileDownloadPromise
            .finally(() => setDownloading(false));
    }, [downloadFile]);

    return {
        isDownloading,
        onDownload
    };
};
