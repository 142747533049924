import type { WritableDraft } from "immer/dist/internal";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ApiState } from "infrastructure/api";
import { MenuItem } from "consts/consumers";
import { compareIds } from "util/support";
import type { ThunkReturnType } from "types";
import type { BlacklistedConsumerResponse, Consumer, ConsumersResponse, ConsumersState } from "./types";
import initialState from "./state";

export const updateConsumerEntityById = (consumersTab: MenuItem) => (
    state: WritableDraft<ConsumersState>,
    payload: Partial<Consumer>
) => {
    const consumersTableSlice = state[consumersTab].tableSlice as ConsumersResponse['data'];

    state[consumersTab].tableSlice!.data = consumersTableSlice!.data.map(resourceEntity => {
        if (compareIds(resourceEntity.coreId, payload.coreId!)) {
            return {
                ...resourceEntity,
                ...payload
            };
        }

        return resourceEntity;
    });
};

export const consumersRequestLoading = (consumersTab: MenuItem) =>
    (state: WritableDraft<ConsumersState>) => {
        state[consumersTab].tableLoadingState = ApiState.Pending;
    };

export const consumersRequestFulfilled = (consumersTab: MenuItem) => (
    state: WritableDraft<ConsumersState>,
    { payload }: PayloadAction<ThunkReturnType<ConsumersResponse | BlacklistedConsumerResponse>>
) => {
    state[consumersTab].tableLoadingState = ApiState.Succeeded;
    state[consumersTab].tableSlice = payload!.data;
};

export const consumersRequestRejected = (consumersTab: MenuItem) => (state: WritableDraft<ConsumersState>) => {
    state[consumersTab].tableLoadingState = ApiState.Failed;
};

export const changeExportLoading = (consumersTab: MenuItem) =>
    (state: WritableDraft<ConsumersState>, { payload }: PayloadAction<ApiState>) => {
    state[consumersTab].exportLoadingState = payload;
};

export const resetState = (consumersTab: MenuItem) => (state: WritableDraft<ConsumersState>) => {
    Object.assign(state[consumersTab], initialState[consumersTab]);
};
