import { memo } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Checkbox } from "ui/forms/formik-elements";
import { getFormInputProps } from "util/forms";
import { FormField, type ImportConsumerBlacklistProps } from "./types";

const ImportConsumerBlacklist = ({
    values,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur,
    sx,
    component,
    ...form
}: ImportConsumerBlacklistProps) => (
    <Box
        sx={{
            display: 'grid',
            gap: 2,
            ...sx
        }}
        component={component}
    >
        <TextField
            {...getFormInputProps(FormField.Reason, form)}
            fullWidth
            multiline
            rows={4}
            size='small'
            name={FormField.Reason}
            value={values[FormField.Reason]}
            label='Blacklist reason'
            onChange={handleChange}
            onBlur={handleBlur}
        />
        <Checkbox
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            label='Blacklist as person'
            handleBlur={handleBlur}
            CheckboxProps={{
                inputProps: {
                    'aria-label': 'Blacklist as person'
                },
                name: FormField.AsPerson,
                checked: values[FormField.AsPerson]
            }}
        />
    </Box>
);

export default memo(ImportConsumerBlacklist);
