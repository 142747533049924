import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { WritableDraft } from "immer/dist/internal";
import { MenuItem } from "consts/consumers";
import initialState, { sliceToken } from "./state";
import { getBlacklistedConsumersThunk, getConsumersThunk } from "./thunks";
import type { Consumer, ConsumersState } from "./types";
import {
    consumersRequestFulfilled,
    consumersRequestLoading,
    consumersRequestRejected,
    updateConsumerEntityById,
    resetState,
    changeExportLoading
} from "./reducers";

const slice = createSlice({
    name: sliceToken,
    initialState,
    reducers: {
        resetAllConsumersState: resetState(MenuItem.All),
        resetBlacklistedConsumersState: resetState(MenuItem.Blacklisted),
        changeAllConsumersExportLoading: changeExportLoading(MenuItem.All),
        changeBlacklistedConsumersExportLoading: changeExportLoading(MenuItem.Blacklisted),
        updateAllConsumersEntityById: (state: WritableDraft<ConsumersState>, { payload }: PayloadAction<Partial<Consumer>>) =>
            updateConsumerEntityById(MenuItem.All)(state, payload),
    },
    extraReducers: builder => {
        builder
            .addCase(getConsumersThunk.pending, consumersRequestLoading(MenuItem.All))
            .addCase(getConsumersThunk.fulfilled, consumersRequestFulfilled(MenuItem.All))
            .addCase(getConsumersThunk.rejected, consumersRequestRejected(MenuItem.All))
            .addCase(getBlacklistedConsumersThunk.pending, consumersRequestLoading(MenuItem.Blacklisted))
            .addCase(getBlacklistedConsumersThunk.fulfilled, consumersRequestFulfilled(MenuItem.Blacklisted))
            .addCase(getBlacklistedConsumersThunk.rejected, consumersRequestRejected(MenuItem.Blacklisted));
    }
});

export const {
    name,
    actions: {
        changeAllConsumersExportLoading,
        changeBlacklistedConsumersExportLoading,
        resetAllConsumersState,
        resetBlacklistedConsumersState,
        updateAllConsumersEntityById
    }
} = slice;

export default slice.reducer;
