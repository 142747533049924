import { useState } from "react";
import type { CustomFile } from "../types";

export default function useUpload() {
    const [files, setFiles] = useState<Array<CustomFile>>([]);

    const reset = () => setFiles([]);

    const recordError = (fileOrIndex: CustomFile | number, { message }: {
        readonly message: string;
    }) => (
        setFiles(files => files.map((file, index) => {
            if (Object.is(file, fileOrIndex) || Object.is(index, fileOrIndex)) {
                file.error = {
                    toString: () => message
                };
            }

            return file;
        }))
    );

    const clearError = (file: CustomFile) => (
        setFiles(files => files.map(f => {
            if (Object.is(f.name, file.name)) {
                delete f.error;
            }

            return f;
        }))
    );

    const onDrop = (files: Array<File>) =>
        setFiles(state => [
            ...state,
            ...files
        ]);

    const onRemove = (file: string | CustomFile) =>
        setFiles(files => files.filter(({ name }) => (
            !Object.is(
                name,
                typeof file === 'string'
                    ? file
                    : file.name
            )
        )));

    return {
        files,
        hasFiles: Boolean(files.length),
        onDrop,
        onRemove,
        reset,
        recordError,
        clearError
    };
};
