import { memo } from 'react';
import { selectIsExportConsumersLoading } from 'features/consumers/selectors';
import { TableToolbar, type TableToolbarProps } from 'ui/widgets/Table';
import { useConsumerContext } from '../ConsumerProvider';

const Toolbar = ({ onExport }: Pick<TableToolbarProps, 'onExport'>) => {
    const { consumersTab } = useConsumerContext();

    return (
        <TableToolbar
            onExport={onExport}
            selectIsExportLoading={selectIsExportConsumersLoading(consumersTab)}
        />
    );
};

export default memo(Toolbar);
