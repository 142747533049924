import { memo } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ApiRoutes } from "consts/enpoints/api";
import useActions from "features/general/useActions";
import { NotificationSeverity } from "features/general/types";
import { ImportConsumerBlacklist } from "ui/forms/Consumer";
import FileUploader from "ui/widgets/FileUploader";
import ImportBlacklistConsumersTemplate from "./ImportBlacklistConsumersTemplate";

const ImportBlacklistConsumers = () => {
    const { showNotication } = useActions();
    const importConsumerBlacklistForm = ImportConsumerBlacklist.useForm();

    const onSuccess = () => showNotication({
        message: 'Blacklist imported successfully',
        severity: NotificationSeverity.Success
    });

    const supportedFormatsMessage = (
        <Typography
            sx={{
                fontFamily: 'var(--primary-font)',
                fontSize: 12,
                color: 'grey.500',
                fontWeight: 600,
                textDecoration: 'underline'
            }}
            gutterBottom
        >
            Supported formats: .csv, UTF-8 encoded, comma-separated
        </Typography>
    );

    return (
        <FileUploader
            url={ApiRoutes.PostImportConsumerBlacklist}
            getBody={() => importConsumerBlacklistForm.values}
            onSuccess={onSuccess}
            TriggerComponentProps={{
                children: 'Blacklist .CSV'
            }}
            ModalProps={{
                titleSlot: 'Upload Blacklist(s) CSV'
            }}
            FileDropAreaProps={{
                children: supportedFormatsMessage
            }}
            UploadButtonProps={{
                disabled: !importConsumerBlacklistForm.isValid
            }}
            after={(
                <ImportConsumerBlacklist.Form
                    {...importConsumerBlacklistForm}
                    sx={{
                        mt: 2
                    }}
                />
            )}
        >
            <Box
                sx={{
                    ml: 1,
                    mb: 2
                }}
            >
                {supportedFormatsMessage}
                <ImportBlacklistConsumersTemplate />
            </Box>
        </FileUploader>
    );
};

export default memo(ImportBlacklistConsumers);
