import { useCallback } from "react";
import { AsyncThunk } from "@reduxjs/toolkit";
import useActions from "features/general/useActions";
import { NotificationSeverity, Notification } from "features/general/types";
import { useAppDispatch } from "./store";
import type { ResourceResponse, NotificationBody } from "types";
import { isCodeIncludes, isResponseValid } from "features/auth/helpers";
import { logout } from "features/auth/slice";
import { ResponseCode } from "consts/api";
import { transformErrorResponseIntoMessage } from "util/transformers";
import { isResponseCode, ManagementApiResponseAdapter } from "util/api";

export const enum ProcessResponseStrategy {
    Json = 'json',
    Text = 'text',
    Blob = 'blob'
};

type UseRequestArgs<TResponse, TRequestParams> = {
    readonly shouldNotifyOnReject?: (code?: number) => boolean,
    readonly thunk: AsyncThunk<TResponse, TRequestParams, {}>;
};

const useRequest = <TResponse, TRequestParams>({
    thunk,
    shouldNotifyOnReject = (code?: number) => !code || !isCodeIncludes(code)
}: UseRequestArgs<TResponse, TRequestParams>) => {
    const dispatch = useAppDispatch();
    const { showNotication } = useActions();

    return useCallback((params: TRequestParams) => {
        const request = dispatch(thunk(params));

        return [
            request
                .then(result => {
                    if (thunk.fulfilled.match(result)) {
                        const payload = ManagementApiResponseAdapter.from<TResponse>(
                            result.payload,
                            { ok: true, status: 200, statusText: 'OK' }
                        );

                        if (!payload.success && shouldNotifyOnReject(payload.code)) {
                            showNotication(getNotificationSettings(payload));
                            // showNotication({
                            //     message: payload.message,
                            //     severity: NotificationSeverity.Error
                            // });
                        }

                        return payload as TResponse;
                    }

                    if (shouldNotifyOnReject() &&
                        !result.meta.aborted &&
                        !result.meta.condition) {
                        showNotication(getNotificationSettings(result.payload as ResourceResponse<null>));
                        // showNotication({
                        //     message: (result.payload as ResourceResponse<null>).message,
                        //     severity: NotificationSeverity.Error
                        // });
                    }
                }),

            request
        ];
    }, [
        dispatch,
        showNotication,
        thunk,
        shouldNotifyOnReject
    ]);
};

export default useRequest;

export type UseSimpleRequestArg = {
    readonly setLoading?: (isLoading: boolean) => void;
    readonly processResponseStrategy?: ProcessResponseStrategy;
    readonly notifyOnSuccess?: boolean;
    readonly onSuccess?: (response: ResourceResponse<any>) => void;
    readonly onError?: (response: ResourceResponse<any>) => void;
};

export type UseSimpleRequestReturnCallbackArg<T = any> = Partial<{
    readonly notifyOnSuccess: boolean;
    readonly onSuccess: (response: ResourceResponse<T>) => void;
    readonly onError: (response: ResourceResponse<T>) => void;
}>;

export const useSimpleRequest = ({
    setLoading,
    processResponseStrategy = ProcessResponseStrategy.Json
}: UseSimpleRequestArg = {}) => {
    const dispatch = useAppDispatch();
    const { showNotication } = useActions();

    return async <T = any>(apiHandler: () => Promise<Response>, {
        notifyOnSuccess = false,
        onSuccess = (_: ResourceResponse<T>) => { },
        onError = (_: ResourceResponse<T>) => { }
    } = {} as UseSimpleRequestReturnCallbackArg<T>) => {
        try {
            setLoading?.(true);

            const response = await apiHandler();

            const payload: ResourceResponse<T> = ManagementApiResponseAdapter.from<T>(
                await response[processResponseStrategy](),
                response
            );

            if (!payload.success) {
                if (!isResponseValid(payload)) {
                    dispatch(logout());
                }

                showNotication(getNotificationSettings({
                    ...payload,
                    code: ResponseCode.BadRequest
                }));

                if (isResponseCode(payload, ResponseCode.UnprocessibleEntity)) {
                    showNotication(getNotificationSettings(payload));
                }

                onError(payload);

                return payload;
            }

            if (notifyOnSuccess) {
                showNotication(getNotificationSettings(payload));
            }

            onSuccess(payload);

            return payload;
        } finally {
            setLoading?.(false);
        }
    };
};

function getNotificationSettings<T>({
    success,
    data,
    message
}: ResourceResponse<T>): Notification {
    if (success) {
        const queuedMessage = (data as NotificationBody)?.message;

        return {
            message: queuedMessage || message,
            severity: queuedMessage
                ? NotificationSeverity.Info
                : NotificationSeverity.Success
        };
    }

    return {
        severity: NotificationSeverity.Error,
        message: data instanceof Object
            ? transformErrorResponseIntoMessage(data as Record<string, string[]>)
            : message
    };
}
