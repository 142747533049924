import { useTypedSelector } from "hooks";
import { selectIsConsumersLoading } from "features/consumers/selectors";
import { useAllConsumersActions } from "features/consumers/actions";
import { FilterVariant, date } from "consts/filters";
import useCellProps from "./useCellProps";
import useFiltersProps from "./useFiltersProps";
import useTableActions from "./useTableActions";
import { MenuItem, filters } from "consts/consumers";
import { useConsumerContext } from "ui/organizms/Consumers/ConsumerProvider";

export default function useTable() {
    const isConsumersLoading = useTypedSelector(selectIsConsumersLoading(MenuItem.All));

    const { selectionModel, setSelectionModel } = useConsumerContext();

    const CellProps = useCellProps();

    const FiltersProps = useFiltersProps();

    const {
        getInitialState,
        getColumnsOverrides
    } = useTableActions();

    const {
        getConsumers,
        downloadCsv
    } = useAllConsumersActions();

    const consumerFilters = filters.get(MenuItem.All)!;

    const filterTypes = new Map<FilterVariant, string[]>([
        [FilterVariant.Text, consumerFilters[FilterVariant.Text]!],
        [FilterVariant.DateRange, date],
        [FilterVariant.MultiOption, consumerFilters[FilterVariant.MultiOption]!],
        [FilterVariant.SingleOption, consumerFilters[FilterVariant.SingleOption]!],
        [FilterVariant.NumericRange, consumerFilters[FilterVariant.NumericRange]!]
    ]);

    const isRowSelectable = () => !isConsumersLoading;

    return {
        isConsumersLoading,
        isRowSelectable,
        getConsumers,
        getInitialState,
        getColumnsOverrides,
        downloadCsv,
        filterTypes,
        CellProps,
        FiltersProps,
        selectionModel,
        setSelectionModel
    };
}
