import type { AsyncThunk, AsyncThunkOptions } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiState } from "infrastructure/api";

import type { RootState } from "infrastructure/store";
import { ApiRouteTypes } from "consts/enpoints/api";
import { MenuItem } from "consts/consumers";
import { payloadCreator } from "util/api";
import { concat } from "util/support";
import { sliceToken } from "./state";
import type { BlacklistedConsumerResponse, ConsumersResponse } from "./types";
import { getConsumers, getConsumersBlacklist } from "features/consumers/api";
import type { ThunkReturnType } from "types";

const getThunkOptions = <TArgs extends unknown>(consumersTab: MenuItem): AsyncThunkOptions<TArgs> => ({
    condition: (_, { getState }) => {
        const { consumers } = getState() as RootState;

        return ![ApiState.Pending].includes(consumers[consumersTab].tableLoadingState);
    }
});

export const getConsumersThunk: AsyncThunk<
    ThunkReturnType<ConsumersResponse>,
    string,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetConsumers]),
    payloadCreator(getConsumers),
    getThunkOptions<string>(MenuItem.All)
);

export const getBlacklistedConsumersThunk: AsyncThunk<
    ThunkReturnType<BlacklistedConsumerResponse>,
    string,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetConsumersBlacklist]),
    payloadCreator(getConsumersBlacklist),
    getThunkOptions<string>(MenuItem.Blacklisted)
);
