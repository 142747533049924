import { useRequest } from "hooks";
import { ApiRouteTypes } from "consts/enpoints/api";
import useExportTable from "hooks/useExportTable";
import type { ThunkReturnType } from "types";
import { changeBlacklistedConsumersExportLoading as changeExportLoading } from "../slice";
import type { BlacklistedConsumerResponse } from "../types";
import { getBlacklistedConsumersThunk } from "../thunks";

const useBlacklistedConsumersActions = () => {
    const getConsumers = useRequest<ThunkReturnType<BlacklistedConsumerResponse>, string>({
        thunk: getBlacklistedConsumersThunk
    });

    const downloadCsv = useExportTable({
        changeExportLoading,
        apiResourceType: ApiRouteTypes.GetConsumersBlacklist
    });

    return {
        getConsumers,
        downloadCsv
    };
};

export default useBlacklistedConsumersActions;
