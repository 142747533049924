import { useAppDispatch, useRequest } from "hooks";
import { ApiRouteTypes } from "consts/enpoints/api";
import useExportTable from "hooks/useExportTable";
import type { ThunkReturnType } from "types";
import { changeAllConsumersExportLoading as changeExportLoading, updateAllConsumersEntityById } from "../slice";
import type { Consumer, ConsumersResponse } from "../types";
import { getConsumersThunk } from "../thunks";

const useAllConsumersActions = () => {
    const dispatch = useAppDispatch();
    const getConsumers = useRequest<ThunkReturnType<ConsumersResponse>, string>({
        thunk: getConsumersThunk
    });

    const downloadCsv = useExportTable({
        changeExportLoading,
        apiResourceType: ApiRouteTypes.GetConsumers
    });

    const updateConsumerById = (consumer: Partial<Consumer>) =>
        dispatch(updateAllConsumersEntityById(consumer));

    return {
        getConsumers,
        downloadCsv,
        updateConsumerById
    };
};

export default useAllConsumersActions;
