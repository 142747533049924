import { memo, type PropsWithChildren } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import BusinessLogicException from "exceptions/BusinessLogicException";
import type { DialogCloseHandler } from "ui/atoms/Modal";
import { TriggerableDialog } from "ui/molecules/Dialog";
import type { FileUploaderProps } from "./types";
import FileDropArea from "./FileDropArea";
import { useFileUploader, useFileRejection } from "./hooks";

const FileUploader = ({
    children,
    before,
    after,
    FileDropAreaProps,
    TriggerComponentProps,
    ModalProps = {},
    ...restProps
}: PropsWithChildren<FileUploaderProps>) => {
    const fileUploader = useFileUploader(restProps);

    const {
        isProcessing,
        hasFiles,
        upload
    } = fileUploader;

    const onClose: DialogCloseHandler = (_: unknown, reason?: string) => {
        const handleDefaultClose = () => {
            if (!Object.is(reason, 'backdropClick')) {
                return;
            }

            throw new BusinessLogicException('🖕🖕🖕', {});
        };

        return (ModalProps.onClose ?? handleDefaultClose)();
    };

    return (
        <TriggerableDialog
            {...restProps}
            TriggerComponent={({ onClick }) => (
                <LoadingButton
                    color='secondary'
                    variant='contained'
                    {...TriggerComponentProps}
                    onClick={onClick}
                />
            )}
            DialogActionsComponent={({ onClose }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                    }}
                >
                    <Button
                        disabled={isProcessing}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant='contained'
                        disabled={!hasFiles}
                        loading={isProcessing}
                        onClick={() => upload()
                            .then(() => onClose())
                            .catch(() => null)}
                    >
                        Upload Files
                    </LoadingButton>
                </Box>
            )}
            ModalProps={{
                DialogProps: {
                    sx: {
                        '& .MuiDialog-container .MuiPaper-root': {
                            width: 'auto',
                            '& .MuiDialogContent-root': {
                                border: 'none'
                            }
                        },
                        '& .MuiDialogActions-root': {
                            justifyContent: 'flex-end'
                        }
                    }
                },
                ...ModalProps,
                onClose
            }}
        >
            {children ?? before}
            <FileDropArea
                {...useFileRejection()}
                {...fileUploader}
                {...FileDropAreaProps}
            />
            {after}
        </TriggerableDialog>
    );
};

export default memo(FileUploader);
