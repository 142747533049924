type Downloadable<T extends object> = {
    readonly fileName: string;
} & T;

export const linkDownload = ({ url, fileName }: Downloadable<{
    readonly url: string;
}>) => {
    const a = document.createElement('a');

    a.style.display = 'none';

    a.href = url;

    a.download = fileName;

    document.body.appendChild(a);
    a.click();

    a.remove();
};

export const blobDownload = ({ fileName,  obj }: Downloadable<{
    readonly obj: Blob | MediaSource
}>) => {
    const url = window.URL.createObjectURL(obj);

    linkDownload({ url, fileName });

    window.URL.revokeObjectURL(url);
};
