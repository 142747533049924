import {
    memo,
    type FC
} from "react";
import blue from "@mui/material/colors/blue";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Upload } from 'lib/upload';
import type { FileDropAreaProps } from "./types";

const FileUploader: FC<FileDropAreaProps> = ({
    children,
    ...restProps
}) => (
    <Upload
        multiple
        placeholderProps={{
            titleSlot: (
                <Box>
                    <Typography gutterBottom variant="h6">
                        Drop or Select .CSV file
                    </Typography>
                    <Box
                        sx={{
                            '& *': {
                                fontSize: 14,
                                fontWeight: 600,
                                textDecoration: 'underline',
                                color: blue[700]
                            }
                        }}
                    >
                        {children}
                    </Box>
                </Box>
            )
        }}
        {...restProps}
    />
);

FileUploader.defaultProps = {
    accept: {
        'text/csv': ['.csv']
    }
};

export default memo(FileUploader);
