import type { ID } from "types";
import { blobDownload } from "util/download";
import { ApiStrategies, RefreshToken } from "./helpers";
import { DownloadableRequestInfo, ExtendedRequestInfo, FormSerializers } from "./types";

const fetchApi = async <T>(
    url: string,
    init?: ExtendedRequestInfo<T>
) => {
    const {
        body,
        headers = {},
        method,
        signal,
        baseUrl = process.env.REACT_APP_BACKEND_ORIGIN_URL,
        abortController = new AbortController(),
        // credentials = 'include',
        // mode ='cors',
        serializer = FormSerializers.Json,
        getBody = ApiStrategies[serializer].getBody,
        getHeaders = ApiStrategies[serializer].getHeaders,
        ...initObject
    } = init ?? {} as ExtendedRequestInfo<T>;

    await RefreshToken.refresh()
        .catch(() => window.location.reload());

    const headersRequest = getHeaders({
        ...headers,
    });

    return fetch(`${baseUrl}${url}`, {
        ...getBody<T>(body),
        method,
        headers: headersRequest,
        signal: signal ?? abortController.signal,
        // credentials,
        ...initObject,
    });
};

type CreateOrUpdateResourceArgs<T> = {
    readonly body: Partial<T>;
    readonly getRoute: (id?: ID) => string;
    readonly getId?: (body: Partial<T>) => ID | undefined;
};

export function createOrUpdateResource<T>({
    body,
    getRoute,
    getId
}: CreateOrUpdateResourceArgs<T>) {
    const resourceId = getId?.(body);

    return fetchApi(getRoute(resourceId), {
        method: resourceId ? 'PUT' : 'POST',
        body,
        serializer: FormSerializers.Json
    });
};

export const downloadFileApi = async <T>(
    url: string,
    { fileName, ...extendedRequestSettings }: DownloadableRequestInfo<T>
) => {
    console.log('Step 1. Enter downloadFileApi function => ', { url, fileName, ...extendedRequestSettings });
    const response = await RefreshToken.refresh({ force: true })
        .catch(() => window.location.reload())
        .finally()
        .then(() => fetchApi(url, extendedRequestSettings));
    console.log('Step 2. Refresh token => ');

    if (response.ok) {
        console.log('Step 3. Successfully refreshed token, start retreving Blob from response!!!!! => ');
        // 1. Go and serach for FE file download library
        // 2. BE need to upload temp file to S3 and return URL

        /// Renalda problem in response.blob() it stuck forever
        console.log('Step 4. Retreving Blob from response!!!!! => ');
        const obj = await response.blob();

        console.log('Step 5. Blob successfully retrieved, start downloading => ', obj);
        blobDownload({
            fileName,
            obj
        });
    }

    return response;
};

export default fetchApi;
