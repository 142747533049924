import { memo } from "react";
import Screen from "ui/layouts/Screen";
import { BlacklistedConsumers } from "ui/organizms/Consumers";

const BlacklistedConsumersScreen = () => (
    <Screen
        title='Consumers | Blacklisted'
    >
        <BlacklistedConsumers />
    </Screen>
);

export default memo(BlacklistedConsumersScreen);
