import { useState } from "react";
import type { CustomFile } from "../types";

export default function useUpload() {
    const [files, setFiles] = useState<Array<CustomFile>>([]);

    const reset = () => setFiles([]);

    const onDrop = (files: Array<File>) =>
        setFiles(state => [
            ...state,
            ...files
        ]);

    const onRemove = (file: string | CustomFile) =>
        setFiles(files => files.filter(({ name }) => (
            !Object.is(
                name,
                typeof file === 'string'
                    ? file
                    : file.name
            )
        )));

    return {
        files,
        hasFiles: Boolean(files.length),
        setFiles,
        onDrop,
        onRemove,
        reset
    };
};
