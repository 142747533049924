import { memo, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { downloadImportConsumerBlacklistTemplate } from "features/consumers/api";
import { useSimpleRequest } from "hooks/useRequest";

const ImportBlacklistConsumersTemplate = () => {
    const [isLoading, setLoading] = useState(false);

    const request = useSimpleRequest({
        setLoading
    });

    const downloadTemplate = () => request(downloadImportConsumerBlacklistTemplate, {
        notifyOnSuccess: true
    });

    return (
        <LoadingButton
            variant='outlined'
            color='info'
            loading={isLoading}
            onClick={downloadTemplate}
        >
            Download Template
        </LoadingButton>
    );
};

export default memo(ImportBlacklistConsumersTemplate);
