import { useState } from "react";
import { useFileUpload } from "hooks";
import { useUpload, type CustomFile } from "lib/upload";
import BusinessLogicException from "exceptions/BusinessLogicException";
import { getPromiseSettledResourceResult } from "util/resource";
import type { UseFileUploaderArg } from "../types";

export default function useFileUploader({
    url,
    body,
    getBody
}: UseFileUploaderArg) {
    const [isProcessing, setProcessing] = useState(false);

    const {
        files,
        hasFiles,
        onDrop,
        onRemove,
        reset,
        recordError,
        clearError
    } = useUpload();

    const uploadFiles = useFileUpload();

    const getUploadFilesBody = (file: CustomFile) => ({
        url,
        files: [
            {
                ...(body || {}),
                ...getBody?.(),
                file
            }
        ]
    });

    const upload = async () => {
        setProcessing(true);

        const responses = await Promise.allSettled(
            files.map(file => uploadFiles(
                getUploadFilesBody(file)
            ))
        );

        let hasError = false;

        responses.forEach((response, index) => {
            const result = getPromiseSettledResourceResult(response);

            if (result && !result.success) {
                recordError(index, result);

                hasError = true;
            }
        });

        if (hasError) {
            setProcessing(false);

            throw new BusinessLogicException('Failed to upload files', {});
        }

        setProcessing(false);

        reset();
    };

    const retry = (file: CustomFile | string) => {
        if (typeof file === 'string') {
            return;
        }

        clearError(file);

        uploadFiles({
            ...getUploadFilesBody(file),
            requestSettings: {
                onError: response => recordError(file, response)
            }
        });
    };

    return {
        isProcessing,
        files,
        hasFiles,
        onDrop,
        onRemove,
        reset,
        recordError,
        clearError,
        upload,
        retry
    };
};
